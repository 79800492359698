import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";
import { environment } from "@env/environment";
import { LayoutService } from "../../services/layout.service";
import { LoginService } from "@app/modules/auth/login/services/login.service";
import { GlobalService } from "@app/shared/services/global/global.service";
import { LocalStorageService } from "../../../../shared/services/local-storage/local-storage.service";

import { Router } from "@angular/router";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  defaultImagePath = environment.defaultImagePath;
  imageUrl = environment.baseImageUrl;
  navItems: any = [];

  navItems_admin = [
    // Dashboard type
    {
      id: 1,
      displayName: "Dashboard",
      iconName: "fa fa-dashboard",

      children: [
        {
          displayName: "Admin Dashboard",
          iconName: "fa fa-dashboard",
          route: "dashboard/admin",
        },
        // {
        //   displayName: "Staff Dashboard",
        //   iconName: "fa fa-dashboard",
        //   route: "dashboard/staff-dashboard",
        // },
      ],
    },

    // Employees part
    {
      id: 2,
      displayName: "Employees",
      iconName: "fa fa-th",

      children: [
        {
          displayName: "Manage Employee",
          iconName: "fa fa-fw fa-user",
          route: "staff/manage-staff",
        },
        {
          displayName: "Manage User",
          iconName: "fa fa-users",
          route: "staff/manage-user",
        },
      ],
    },

    // Client Part
    {
      id: 3,
      displayName: "Manage Clients",
      iconName: "fa fa-users",
      // route: "client"
      children: [
        {
          displayName: "Clients",
          iconName: "fa fa-users",
          route: "manage-client/client",
        },
        {
          displayName: "Client Branch",
          iconName: "fa fa-code-fork",
          route: "manage-client/client-branch",
        },
        // {
        //   displayName: "Clients Wise Attendance Report",

        //   route: "manage-client/clientwise-report",
        // },
        // {
        //   displayName: "Client Employee",
        //   iconName: "fa fa-user",
        //   route: "manage-client/client-employee",
        // },
      ],
    },

    // Dailybook Management part
    {
      id: 4,
      displayName: "Daybook Management",
      iconName: "fa fa-bar-chart-o",

      children: [
        {
          displayName: "Correction Request",
          iconName: "fa fa-edit",
          route: "daybook-management/correction-attendance",
        },

        {
          displayName: "Detailed Time",
          iconName: "fa fa-file",
          route: "reports/monthly-report",
        },

        {
          displayName: "Leave Request",
          iconName: "fa fa-paper-plane",
          children: [
            {
              displayName: "Create",
              iconName: "fa fa-download",
              route: "leave-request/create",
            },
            {
              displayName: "Request Received",
              iconName: "fa fa-paper-plane",
              route: "leave-request/request-received",
            },
          ],
        },
      ],
    },

    // Report Section
    {
      id: 5,
      displayName: "Reports",
      iconName: "fa fa-file",

      children: [
        {
          displayName: "Attendance Detail",
          iconName: "fa fa-check-square",
          route: "reports/attendance-detail",
        },

        {
          displayName: "Time Card",
          iconName: "fa fa-clock-o",
          route: "reports/timecard",
        },
      ],
    },

    // Settings Part
    {
      id: 9,
      displayName: "Setting",
      iconName: "fa fa-gears",

      children: [
        {
          displayName: "General Setting",
          iconName: "fa fa-gears",
          route: "setting/general-setting",
        },
      ],
    },
  ];

  navItems_manager = [
    // Dashboard part
    {
      id: 1,
      displayName: "Dashboard",
      iconName: "fa fa-dashboard",
      children: [
        {
          displayName: "Staff Dashboard",
          iconName: "fa fa-dashboard",
          route: "dashboard/staff-dashboard",
        },
      ],
    },

    // Employees part
    {
      id: 2,
      displayName: "Employees",
      iconName: "fa fa-th",
      children: [
        {
          displayName: "Manage User",
          iconName: "fa fa-users",
          route: "staff/manage-user",
        },
      ],
    },

    // Clients part
    {
      id: 3,
      displayName: "Manage Clients",
      iconName: "fa fa-users",
      // route: "client"
      children: [
        {
          displayName: "Clients",
          iconName: "fa fa-users",
          route: "manage-client/client",
        },
      ],
    },

    // Daybook Management
    {
      id: 4,
      displayName: "Daybook Management",
      iconName: "fa fa-bar-chart-o",

      children: [
        {
          displayName: "Monthly Report",
          iconName: "fa fa-file",
          route: "reports/monthly-report",
        },

        {
          displayName: "Leave Request",
          iconName: "fa fa-paper-plane",
          children: [
            {
              displayName: "Create",
              iconName: "fa fa-download",
              route: "leave-request/create",
            },
            {
              displayName: "Request Received",
              iconName: "fa fa-paper-plane",
              route: "leave-request/request-received",
            },
          ],
        },
      ],
    },

    // Report Section
    {
      id: 5,
      displayName: "Reports",
      iconName: "fa fa-file",

      children: [
        {
          displayName: "Attendance Detail",
          iconName: "fa fa-check-square",
          route: "reports/attendance-detail",
        },

        {
          displayName: "Time Card",
          iconName: "fa fa-clock-o",
          route: "reports/timecard",
        },
      ],
    },
  ];

  navItems_employee = [
    // Dashboard part
    {
      id: 1,
      displayName: "Dashboard",
      iconName: "fa fa-dashboard",
      children: [
        {
          displayName: "Staff Dashboard",
          iconName: "fa fa-dashboard",
          route: "dashboard/staff-dashboard",
        },
      ],
    },

    // Daybook Management part
    {
      id: 4,
      displayName: "Daybook Management",
      iconName: "fa fa-bar-chart-o",

      children: [
        {
          displayName: "Attendence",
          iconName: "fa fa-check-square",
          route: "daybook-management/attendance",
        },
        {
          displayName: "Correction Attendance",
          iconName: "fa fa-edit",
          route: "daybook-management/correction-attendance",
        },
      ],
    },

    // Reports Part
    {
      id: 5,
      displayName: "Reports",
      iconName: "fa fa-file",

      children: [
        {
          displayName: "Monthly Report",
          iconName: "fa fa-file",
          route: "reports/monthly-report",
        },

        // {
        //   displayName: "Yearly/Monthly Report",
        //   iconName: "fa fa-file",
        //   route: "reports/yearly-monthly-reports",
        // },
        // {
        //   displayName: "Break Report",
        //   iconName: "fa fa-file",
        //   route: "reports/break-report",
        // },
      ],
    },

    // Leave Request
    {
      id: 6,
      displayName: "Leave Request",
      iconName: "fa fa-paper-plane",

      children: [
        {
          displayName: "Create",
          iconName: "fa fa-download",
          route: "leave-request/create",
        },
        {
          displayName: "Request Received",
          iconName: "fa fa-paper-plane",
          route: "leave-request/request-received",
        },
      ],
    },
  ];
  isPinLogin;
  constructor(
    private layoutService: LayoutService,
    private loginService: LoginService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.getUserInfo();
    this.getRoleList();
    this.isPinLogin = this.localStorageService.getLocalStorageItem("isPinLogin")
      ? this.localStorageService.getLocalStorageItem("isPinLogin")
      : false;
    //assign the usermenu here
    this.navItems =
      this.localStorageService.getLocalStorageItem("userMenuDetails");
  }

  userName: string;
  userInfo: any;
  getUserInfo() {
    this.userInfo = this.loginService.getUserInfoFromStorage();
    this.userName = this.layoutService.getUserFullName();
  }

  toogleSideNav() {
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
  }

  selectedRole;
  getRoleList(): void {
    this.selectedRole = this.localStorageService.getLocalStorageItem("role");
  }
}
