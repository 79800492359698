import { Component, OnInit } from "@angular/core";
import { DatePipe } from '@angular/common';

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  currentDate: string;
  constructor(private datePipe: DatePipe) {
    const now = new Date();
    this.currentDate = this.datePipe.transform(now, 'yyyy');
  }

  ngOnInit() {}
}
