import { Injectable } from '@angular/core';
import { LocalStorageService } from "@app/shared/services/local-storage/local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class RouteCheckService {

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  isRouteExist(routeParam): boolean {
    const userMenu: any[] = this.localStorageService.getLocalStorageItem("userMenuDetails");

    if (userMenu) {
      const value = userMenu.filter((x) => x.angular_route == routeParam)[0];
      if (value != null) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }
}
