export const GlobalConstants = {
  countryList: [
    { name: "Afghanistan", code: "AF" },
    { name: "land Islands", code: "AX" },
    { name: "Albania", code: "AL" },
    { name: "Algeria", code: "DZ" },
    { name: "American Samoa", code: "AS" },
    { name: "AndorrA", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctica", code: "AQ" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermuda", code: "BM" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia", code: "BO" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Bouvet Island", code: "BV" },
    { name: "Brazil", code: "BR" },
    { name: "British Indian Ocean Territory", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cape Verde", code: "CV" },
    { name: "Cayman Islands", code: "KY" },
    { name: "Central African Republic", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Christmas Island", code: "CX" },
    { name: "Cocos (Keeling) Islands", code: "CC" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros", code: "KM" },
    { name: "Congo", code: "CG" },
    { name: "Congo, The Democratic Republic of the", code: "CD" },
    { name: "Cook Islands", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    { name: 'Cote D"Ivoire', code: "CI" },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Cyprus", code: "CY" },
    { name: "Czech Republic", code: "CZ" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Ethiopia", code: "ET" },
    { name: "Falkland Islands (Malvinas)", code: "FK" },
    { name: "Faroe Islands", code: "FO" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "French Guiana", code: "GF" },
    { name: "French Polynesia", code: "PF" },
    { name: "French Southern Territories", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Heard Island and Mcdonald Islands", code: "HM" },
    { name: "Holy See (Vatican City State)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran, Islamic Republic Of", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Isle of Man", code: "IM" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: 'Korea, Democratic People"S Republic of', code: "KP" },
    { name: "Korea, Republic of", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    { name: 'Lao People"S Democratic Republic', code: "LA" },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libyan Arab Jamahiriya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia, Federated States of", code: "FM" },
    { name: "Moldova, Republic of", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montenegro", code: "ME" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands", code: "NL" },
    { name: "Netherlands Antilles", code: "AN" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "Northern Mariana Islands", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestinian Territory, Occupied", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "Reunion", code: "RE" },
    { name: "Romania", code: "RO" },
    { name: "Russian Federation", code: "RU" },
    { name: "RWANDA", code: "RW" },
    { name: "Saint Helena", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia", code: "RS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard and Jan Mayen", code: "SJ" },
    { name: "Swaziland", code: "SZ" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syrian Arab Republic", code: "SY" },
    { name: "Taiwan, Province of China", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania, United Republic of", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates", code: "AE" },
    { name: "United Kingdom", code: "GB" },
    { name: "United States", code: "US" },
    { name: "United States Minor Outlying Islands", code: "UM" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Virgin Islands, British", code: "VG" },
    { name: "Virgin Islands, U.S.", code: "VI" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" },
  ],
  timeZone: [
    { name: "Pacific/Midway", value: "(GMT-11:00) Midway Island" },
    { name: "US/Samoa", value: "(GMT-11:00) Samoa" },
    { name: "US/Hawaii", value: "(GMT-10:00) Hawaii" },
    { name: "US/Alaska", value: "(GMT-09:00) Alaska" },
    { name: "US/Pacific", value: "(GMT-08:00) Pacific Time (US &amp; Canada)" },
    { name: "America/Tijuana", value: "(GMT-08:00) Tijuana" },
    { name: "US/Arizona", value: "(GMT-07:00) Arizona" },
    {
      name: "US/Mountain",
      value: "(GMT-07:00) Mountain Time (US &amp; Canada)",
    },
    { name: "America/Chihuahua", value: "(GMT-07:00) Chihuahua" },
    { name: "America/Mazatlan", value: "(GMT-07:00) Mazatlan" },
    { name: "America/Mexico_City", value: "(GMT-06:00) Mexico City" },
    { name: "America/Monterrey", value: "(GMT-06:00) Monterrey" },
    { name: "Canada/Saskatchewan", value: "(GMT-06:00) Saskatchewan" },
    { name: "US/Central", value: "(GMT-06:00) Central Time (US &amp; Canada)" },
    { name: "US/Eastern", value: "(GMT-05:00) Eastern Time (US &amp; Canada)" },
    { name: "US/East-Indiana", value: "(GMT-05:00) Indiana (East)" },
    { name: "America/Bogota", value: "(GMT-05:00) Bogota" },
    { name: "America/Lima", value: "(GMT-05:00) Lima" },
    { name: "America/Caracas", value: "(GMT-04:30) Caracas" },
    { name: "Canada/Atlantic", value: "(GMT-04:00) Atlantic Time (Canada)" },
    { name: "America/La_Paz", value: "(GMT-04:00) La Paz" },
    { name: "America/Santiago", value: "(GMT-04:00) Santiago" },
    { name: "Canada/Newfoundland", value: "(GMT-03:30) Newfoundland" },
    { name: "America/Buenos_Aires", value: "(GMT-03:00) Buenos Aires" },
    { name: "Greenland", value: "(GMT-03:00) Greenland" },
    { name: "Atlantic/Stanley", value: "(GMT-02:00) Stanley" },
    { name: "Atlantic/Azores", value: "(GMT-01:00) Azores" },
    { name: "Atlantic/Cape_Verde", value: "(GMT-01:00) Cape Verde Is." },
    { name: "Africa/Casablanca", value: "(GMT) Casablanca" },
    { name: "Europe/Dublin", value: "(GMT) Dublin" },
    { name: "Europe/Lisbon", value: "(GMT) Lisbon" },
    { name: "Europe/London", value: "(GMT) London" },
    { name: "Africa/Monrovia", value: "(GMT) Monrovia" },
    { name: "Europe/Amsterdam", value: "(GMT+01:00) Amsterdam" },
    { name: "Europe/Belgrade", value: "(GMT+01:00) Belgrade" },
    { name: "Europe/Berlin", value: "(GMT+01:00) Berlin" },
    { name: "Europe/Bratislava", value: "(GMT+01:00) Bratislava" },
    { name: "Europe/Brussels", value: "(GMT+01:00) Brussels" },
    { name: "Europe/Budapest", value: "(GMT+01:00) Budapest" },
    { name: "Europe/Copenhagen", value: "(GMT+01:00) Copenhagen" },
    { name: "Europe/Ljubljana", value: "(GMT+01:00) Ljubljana" },
    { name: "Europe/Madrid", value: "(GMT+01:00) Madrid" },
    { name: "Europe/Paris", value: "(GMT+01:00) Paris" },
    { name: "Europe/Prague", value: "(GMT+01:00) Prague" },
    { name: "Europe/Rome", value: "(GMT+01:00) Rome" },
    { name: "Europe/Sarajevo", value: "(GMT+01:00) Sarajevo" },
    { name: "Europe/Skopje", value: "(GMT+01:00) Skopje" },
    { name: "Europe/Stockholm", value: "(GMT+01:00) Stockholm" },
    { name: "Europe/Vienna", value: "(GMT+01:00) Vienna" },
    { name: "Europe/Warsaw", value: "(GMT+01:00) Warsaw" },
    { name: "Europe/Zagreb", value: "(GMT+01:00) Zagreb" },
    { name: "Europe/Athens", value: "(GMT+02:00) Athens" },
    { name: "Europe/Bucharest", value: "(GMT+02:00) Bucharest" },
    { name: "Africa/Cairo", value: "(GMT+02:00) Cairo" },
    { name: "Africa/Harare", value: "(GMT+02:00) Harare" },
    { name: "Europe/Helsinki", value: "(GMT+02:00) Helsinki" },
    { name: "Europe/Istanbul", value: "(GMT+02:00) Istanbul" },
    { name: "Asia/Jerusalem", value: "(GMT+02:00) Jerusalem" },
    { name: "Europe/Kiev", value: "(GMT+02:00) Kyiv" },
    { name: "Europe/Minsk", value: "(GMT+02:00) Minsk" },
    { name: "Europe/Riga", value: "(GMT+02:00) Riga" },
    { name: "Europe/Sofia", value: "(GMT+02:00) Sofia" },
    { name: "Europe/Tallinn", value: "(GMT+02:00) Tallinn" },
    { name: "Europe/Vilnius", value: "(GMT+02:00) Vilnius" },
    { name: "Asia/Baghdad", value: "(GMT+03:00) Baghdad" },
    { name: "Asia/Kuwait", value: "(GMT+03:00) Kuwait" },
    { name: "Africa/Nairobi", value: "(GMT+03:00) Nairobi" },
    { name: "Asia/Riyadh", value: "(GMT+03:00) Riyadh" },
    { name: "Europe/Moscow", value: "(GMT+03:00) Moscow" },
    { name: "Asia/Tehran", value: "(GMT+03:30) Tehran" },
    { name: "Asia/Baku", value: "(GMT+04:00) Baku" },
    { name: "Europe/Volgograd", value: "(GMT+04:00) Volgograd" },
    { name: "Asia/Muscat", value: "(GMT+04:00) Muscat" },
    { name: "Asia/Tbilisi", value: "(GMT+04:00) Tbilisi" },
    { name: "Asia/Yerevan", value: "(GMT+04:00) Yerevan" },
    { name: "Asia/Kabul", value: "(GMT+04:30) Kabul" },
    { name: "Asia/Karachi", value: "(GMT+05:00) Karachi" },
    { name: "Asia/Tashkent", value: "(GMT+05:00) Tashkent" },
    { name: "Asia/Kolkata", value: "(GMT+05:30) Kolkata" },
    { name: "Asia/Kathmandu", value: "(GMT+05:45) Kathmandu" },
    { name: "Asia/Yekaterinburg", value: "(GMT+06:00) Ekaterinburg" },
    { name: "Asia/Almaty", value: "(GMT+06:00) Almaty" },
    { name: "Asia/Dhaka", value: "(GMT+06:00) Dhaka" },
    { name: "Asia/Novosibirsk", value: "(GMT+07:00) Novosibirsk" },
    { name: "Asia/Bangkok", value: "(GMT+07:00) Bangkok" },
    { name: "Asia/Jakarta", value: "(GMT+07:00) Jakarta" },
    { name: "Asia/Krasnoyarsk", value: "(GMT+08:00) Krasnoyarsk" },
    { name: "Asia/Chongqing", value: "(GMT+08:00) Chongqing" },
    { name: "Asia/Hong_Kong", value: "(GMT+08:00) Hong Kong" },
    { name: "Asia/Kuala_Lumpur", value: "(GMT+08:00) Kuala Lumpur" },
    { name: "Australia/Perth", value: "(GMT+08:00) Perth" },
    { name: "Asia/Singapore", value: "(GMT+08:00) Singapore" },
    { name: "Asia/Taipei", value: "(GMT+08:00) Taipei" },
    { name: "Asia/Ulaanbaatar", value: "(GMT+08:00) Ulaan Bataar" },
    { name: "Asia/Urumqi", value: "(GMT+08:00) Urumqi" },
    { name: "Asia/Irkutsk", value: "(GMT+09:00) Irkutsk" },
    { name: "Asia/Seoul", value: "(GMT+09:00) Seoul" },
    { name: "Asia/Tokyo", value: "(GMT+09:00) Tokyo" },
    { name: "Australia/Adelaide", value: "(GMT+09:30) Adelaide" },
    { name: "Australia/Darwin", value: "(GMT+09:30) Darwin" },
    { name: "Asia/Yakutsk", value: "(GMT+10:00) Yakutsk" },
    { name: "Australia/Brisbane", value: "(GMT+10:00) Brisbane" },
    { name: "Australia/Canberra", value: "(GMT+10:00) Canberra" },
    { name: "Pacific/Guam", value: "(GMT+10:00) Guam" },
    { name: "Australia/Hobart", value: "(GMT+10:00) Hobart" },
    { name: "Australia/Melbourne", value: "(GMT+10:00) Melbourne" },
    { name: "Pacific/Port_Moresby", value: "(GMT+10:00) Port Moresby" },
    { name: "Australia/Sydney", value: "(GMT+10:00) Sydney" },
    { name: "Asia/Vladivostok", value: "(GMT+11:00) Vladivostok" },
    { name: "Asia/Magadan", value: "(GMT+12:00) Magadan" },
    { name: "Pacific/Auckland", value: "(GMT+12:00) Auckland" },
    { name: "Pacific/Fiji", value: "(GMT+12:00) Fiji" },
  ],

  englishMonth: [
    { month: "January", value: "1" },
    { month: "February", value: "2" },
    { month: "March", value: "3" },
    { month: "April", value: "4" },
    { month: "May", value: "5" },
    { month: "June", value: "6" },
    { month: "July", value: "7" },
    { month: "August", value: "8" },
    { month: "September", value: "9" },
    { month: "October", value: "10" },
    { month: "November", value: "11" },
    { month: "December", value: "12" },
  ],
  nepaliMonth: [
    { month: "Baishak", value: "1" },
    { month: "Jestha", value: "2" },
    { month: "Ashad", value: "3" },
    { month: "Shrawan", value: "4" },
    { month: "Bhadra", value: "5" },
    { month: "Ashwin", value: "6" },
    { month: "Kartik", value: "7" },
    { month: "Mangsir", value: "8" },
    { month: "Poush", value: "9" },
    { month: "Magh", value: "10" },
    { month: "Falgun", value: "11" },
    { month: "Chaitra", value: "12" },
  ],

  englishYear: ["2014", "2015", "2016", "2017", "2018", "2019", "2020"],
  NepaliYear: ["2070", "2071", "2072", "2073", "2074", "2075", "2076", "2077","2078","2079","2080","2081","2082"],
};
